import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Table } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import Placeholder from "react-bootstrap/Placeholder";
import ValueDisplay from "common/components/ValueDisplay/ValueDisplay";
import Swal from "sweetalert2";
import { contactToBackendSchema } from "common/helpers/userTransform";
import { updateThesisUserContact } from "modules/thesis/services/api/thesisUserAPI";
import { responseDialog } from "common/helpers/ResponseDialog";
import { validateEmail } from "common/helpers/validations";

export default function ThesisUserInformation(props) {
  const { isLoading, user } = props;
  const [userEdited, setUserEdited] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (!isLoading) {
      setUserData(user);
    }
  }, [isLoading]);

  const handleChangeEmail = (e) => {
    if (e.target.value.trim() === "") return setUserEdited(false);
    setUserEdited(true);
    setUserData({
      ...userData,
      email1: { ...userData?.email1, value: e.target.value },
    });
  };

  const saveEmail = async (e) => {
    e.preventDefault();
    if (!validateEmail(userData?.email1?.value)) {
      return Swal.fire({
        icon: "warning",
        title: "Correo no válido.",
        text: "Por favor revise que el correo ingresado sea válido.",
      });
    }
    setUserEdited(false);
    const response = await updateThesisUserContact(
      contactToBackendSchema(userData)
    );
    await responseDialog(response, "Correo actualizado.");
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1">
          <Table striped bordered>
            <tbody>
              <tr>
                <td className="text-start d-none d-md-table-cell fw-bold">
                  Apellidos y nombres
                </td>
                <td className="text-start">
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  ) : (
                    <ValueDisplay value={userData?.displayname} />
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-start d-none d-md-table-cell fw-bold">
                  CUI
                </td>
                <td
                  className="text-start"
                  style={isLoading ? { width: "75%" } : {}}
                >
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  ) : (
                    <ValueDisplay value={userData?.cui} />
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-start d-none d-md-table-cell fw-bold">
                  Carnet
                </td>
                <td className="text-start">
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  ) : (
                    <ValueDisplay value={userData?.primary_id} />
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-start d-none d-md-table-cell fw-bold">
                  Correo
                </td>
                <td className="text-start">
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  ) : (
                    <Form onSubmit={saveEmail}>
                      <Form.Group as={Row} className="align-items-center">
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Correo electrónico"
                            defaultValue={userData?.email1?.value || ""}
                            onChange={handleChangeEmail}
                          />
                        </Col>
                        <Col className="mobile_center mt-sm-0 mt-2">
                          <Button disabled={!userEdited} type="submit">
                            <FaSave /> Guardar
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-start d-none d-md-table-cell fw-bold">
                  Dirección
                </td>
                <td className="text-start">
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  ) : (
                    <ValueDisplay value={userData?.address1?.value} />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useRef, useState } from "react";
import styles from "./AdvisorsInput.module.scss";
import { QuestionCircle, XCircle } from "react-bootstrap-icons";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import Select from "react-select";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";
import { Button } from "react-bootstrap";

const gradeOptions = [
  { label: "Doctor", value: "Doctor" },
  { label: "Doctora", value: "Doctora" },
  { label: "Ingeniero", value: "Ingeniero" },
  { label: "Ingeniera", value: "Ingeniera" },
  { label: "Licenciado", value: "Licenciado" },
  { label: "Licenciada", value: "Licenciada" },
  { label: "Maestro", value: "Maestro" },
  { label: "Maestra", value: "Maestra" },
  { label: "Tutor", value: "Tutor" },
  { label: "Tutora", value: "Tutora" },
];

const genderOptions = [
  { label: "Asesor", value: "Asesor:" },
  { label: "Asesora", value: "Asesora:" },
];

export default function AdvisorsInput() {
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const [inputValue, setInputValue] = useState("");
  const [gradeSelect, setGradeSelect] = useState(null);
  const [genderSelect, setGenderSelect] = useState(null);
  const inputRef = useRef();

  const saveTag = () => {
    if (!gradeSelect?.value || !(inputValue?.toString()?.trim()?.length > 1))
      return Swal.fire({
        icon: "info",
        title: "Complete los campos.",
        text: "Asegurese de completar todos los campos antes de ingresar un nuevo asesor.",
        showCancelButton: true,
      });
    // if (!(inputValue?.toString()?.trim()?.length > 1)) return;
    const tagValue = {
      label: `${genderSelect?.value} ${gradeSelect?.value} ${inputValue.trim()}`,
    };
    let advisorsList = workInformation?.advisors || [];
    var isAlreadyOnList = advisorsList.find(
      (obj) => obj.label === tagValue.label
    );
    if (isAlreadyOnList) return;
    advisorsList.push(tagValue);
    setWorkInformation({ ...workInformation, advisors: advisorsList });
    setInputValue("");
    setGradeSelect(null);
    setGenderSelect(null);
    inputRef.current.focus();
  };

  // al presionar enter guarda el asesor
  function keyDownInput(event) {
    if (event.keyCode !== 13) return;

    event.stopPropagation();
    event.preventDefault();
    if (event.target.value.trim() === "") return;

    saveTag();
  }

  const deleteTag = (id) => {
    let newList = workInformation?.advisors || [];
    newList = newList.filter((_, indx) => indx !== id);
    setWorkInformation({ ...workInformation, advisors: newList });
    inputRef.current.focus();
  };

  const openAdvisorModal = () => {
    Swal.fire({
      html: `<div><p>Debe ingresar el título de su(s) asesor(es) sin abreviaturas.</p><p>Nombres y apellidos deben escribirse con inicial mayúscula.</p><br/><p>Ejemplo: Licenciado Juan Carlos López Navas</p></div>`,
      icon: "info",
      confirmButtonText: "Entendido",
    });
  };

  return (
    <div className="mt-2 mb-3">
      <div className="d-flex">
        <h5>Asesor(a):</h5>
        <div
          className={`ms-2 d-none d-md-block pe-auto`}
          onClick={openAdvisorModal}
          role="button"
        >
          <QuestionCircle />
        </div>
      </div>
      <p>Nombres y apellidos deben escribirse con inicial mayúscula.</p>
      <div className="row">
        <div className="col-2">
          <Select
            onChange={(e) => {
              setGenderSelect(e);
            }}
            className="form-control p-0"
            options={genderOptions}
            name="gender"
            placeholder="Asesor (a)"
            value={genderSelect}
            menuPlacement="top"
          />
        </div>
        <div className="col-3 p-0" style={{ marginRight: "0.5rem" }}>
          <Select
            onChange={(e) => {
              setGradeSelect(e);
            }}
            className="form-control p-0"
            options={gradeOptions}
            name="workType"
            placeholder="Grado académico"
            value={gradeSelect}
            menuPlacement="top"
          />
        </div>
        <div className={`${styles.wrapper} col`}>
          <div className={styles.inputcont}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Escriba el nombre de su asesor y presione Enter o el botón Agregar."
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              className={styles.input}
              onKeyDown={keyDownInput}
            />
          </div>
          <Button onClick={saveTag}>
            Agregar
            <FaPlus />
          </Button>
        </div>
      </div>
      <div className={`${styles.tagcont} mt-2`}>
        {workInformation?.advisors?.map((advisor, idx) => (
          <div className={styles.tag} key={idx}>
            {advisor?.label}{" "}
            <div
              className={styles.close}
              onClick={() => {
                deleteTag(idx);
              }}
            >
              <XCircle data-tag={idx} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

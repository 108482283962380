import React from "react";
import { Table } from "react-bootstrap";
import ValueDisplay from "common/components/ValueDisplay/ValueDisplay";

export default function AuthorTable(props) {
  const { author } = props;

  return (
    <>
      <Table striped bordered>
        <tbody>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Apellidos y nombres
            </td>
            <td className="text-start">
              <ValueDisplay value={author?.user?.displayname} />
            </td>
          </tr>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">CUI</td>
            <td className="text-start">
              <ValueDisplay value={author?.user?.cui} />
            </td>
          </tr>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Carnet
            </td>
            <td className="text-start">
              <ValueDisplay value={author?.user?.primary_id} />
            </td>
          </tr>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Correo
            </td>
            <td className="text-start">
              <ValueDisplay value={author?.user?.email1?.value} />
            </td>
          </tr>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Grado obtenido
            </td>
            <td className="text-start">
              <ValueDisplay value={author?.grade?.label} />
            </td>
          </tr>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Facultad
            </td>
            <td className="text-start">
              <ValueDisplay value={author?.academic_detail?.unit} />
            </td>
          </tr>
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Carrera
            </td>
            <td className="text-start">
              <ValueDisplay value={author?.academic_detail?.carreer} />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

import React, { useContext } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Select from "react-select";
import styles from "../WorkSummary.module.scss";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import { Form } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import ThesisAcademicGradeSelect from "../ThesisAcademicGradeSelect/ThesisAcademicGradeSelect";

export default function AuthorInformation(props) {
  const { author, remove } = props;
  const { workInformation, setWorkInformation } = useContext(WizardContext);

  let carreerOptions = author?.academic_detail_list?.map((detail) => {
    return { value: detail?.id, label: detail?.carreer };
  });

  const selectCarreer = (event) => {
    let currentAcademicDetail = author?.academic_detail_list?.find((detail) => {
      return detail?.id === event.value;
    });
    let authorList = workInformation?.authors;
    authorList = authorList.map((item) => {
      if (item?.user?.id !== author?.user?.id) return item;
      return {
        ...item,
        academic_detail: {
          label: currentAcademicDetail?.carreer,
          value: currentAcademicDetail?.id,
          selectedFaculty: currentAcademicDetail?.unit,
          unit: currentAcademicDetail?.unit,
          carreer: currentAcademicDetail?.carreer,
        },
      };
    });
    setWorkInformation({ ...workInformation, authors: authorList });
  };

  const getDefaultCarreer = () => {
    let currentOption = author?.academic_detail;
    if (!currentOption) return "";
    return currentOption;
  };

  const handleGradeSelectChange = (e) => {
    if (!e?.value) return;
    let authorList = workInformation?.authors;
    authorList = authorList.map((item) => {
      if (item?.user?.id !== author?.user?.id) return item;
      return {
        ...item,
        grade: e,
      };
    });
    setWorkInformation({ ...workInformation, authors: authorList });
  };

  return (
    <div className={remove ? "border border-dark py-2 px-2" : ""}>
      {remove && (
        <div
          className={`d-flex justify-content-md-end justify-content-center mobile_center flex_sm_column align_items_sm_start mb-2`}
        >
          <Button variant="danger" onClick={remove}>
            <FaTrash /> Eliminar
          </Button>
        </div>
      )}
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          Registro académico
        </InputGroup.Text>
        <Form.Control
          placeholder="Registro académico"
          defaultValue={author?.user?.primary_id}
          disabled
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          CUI
        </InputGroup.Text>
        <Form.Control
          placeholder="CUI"
          defaultValue={author?.user?.cui}
          disabled
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          Apellidos y nombres
        </InputGroup.Text>
        <Form.Control
          placeholder="Apellidos y nombres"
          defaultValue={author?.user?.displayname}
          disabled
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          Correo electrónico
        </InputGroup.Text>
        <Form.Control
          placeholder="Correo electrónico"
          defaultValue={author?.user?.email1?.value}
          disabled
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          Grado académico obtenido
          <sup className="px-1 crud-required">*</sup>
        </InputGroup.Text>
        <ThesisAcademicGradeSelect
          isMulti={false}
          onChangeSelect={handleGradeSelectChange}
          inputValue={author?.grade}
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          Facultad
        </InputGroup.Text>
        <Form.Control
          placeholder="Facultad"
          defaultValue={author?.academic_detail?.selectedFaculty}
          disabled
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
          Carrera
          <sup className="px-1 crud-required">*</sup>
        </InputGroup.Text>
        <Select
          className="form-control p-0"
          options={carreerOptions}
          onChange={selectCarreer}
          name="career"
          placeholder="Carrera"
          defaultValue={getDefaultCarreer}
        />
      </InputGroup>
    </div>
  );
}

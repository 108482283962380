import React from "react";
import { FaInfoCircle } from "react-icons/fa";

export default function FormRequiredValueWarning() {
  return (
    <p className="text-danger my-1">
      <FaInfoCircle className="me-1" />
      Campo obligatorio
    </p>
  );
}
